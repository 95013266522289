// Import the Firebase library and initialize with your credentials
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAiKT8SXW2TETVzjjw4RrsMje1YvDCmdt0",
    authDomain: "todola-b69bf.firebaseapp.com",
    projectId: "todola-b69bf",
    storageBucket: "todola-b69bf.appspot.com",
    messagingSenderId: "42286638795",
    appId: "1:42286638795:web:96cf2bb4d65a39311c1d4e",
    measurementId: "G-D0Y9VEW7ML"
  };

// Initialize Firebase and export the database
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
