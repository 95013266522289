<template>
    <div class="task-creation">
      <!-- Input for Task Title -->
      <input v-model="newTaskTitle" placeholder="Titlu" />
  
      <!-- Input for Task Content -->
      <textarea v-model="newTaskContent" placeholder="Descriere"></textarea>
  
      <!-- Dropdown to Select the Column Type -->
      <select v-model="newTaskType">
        <option value="De luat">De luat</option>
        <option value="Luat">Luat</option>
        <option value="Ne trebuie?">Ne trebuie?</option>
      </select>
  
      <!-- Button to Add the Task -->
      <button @click="addTask">Da-i foc</button>
    </div>
  </template>
  
  <script>
  import { database } from '../firebase';
import { ref as dbRef, push} from 'firebase/database';

  export default {
    data() {
      return {
        newTaskTitle: '',
        newTaskContent: '',
        newTaskType: 'De luat', // Default selection
      };
    },
    methods: {
      addTask() {
        if (this.newTaskTitle.trim() === '') return;
  
        const tasksRef = dbRef(database, 'tasks');
        push(tasksRef, {
          title: this.newTaskTitle,
          content: this.newTaskContent,
          type: this.newTaskType,
          completed: false,
        });
  
        // Clear input fields after task is added
        this.newTaskTitle = '';
        this.newTaskContent = '';
        this.newTaskType = 'De luat'; // Reset to default
      }
    }
  };
  </script>
  
  <style>
  /* Task Creation Styling */
  .task-creation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  input,
  textarea,
  select {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical;
  }
  button {
  padding: 10px;
  font-size: 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>
  
  