<template>
  <div id="app">
    <h1>La cabana bicazel</h1>
    <TaskCreation />
    <div class="task-columns">
      <div
        v-for="type in taskTypes"
        :key="type"
        class="column"
        @dragover.prevent
        @drop="handleDrop($event, type)"
      >
        <h2>{{ type }}</h2>
        <div
          v-for="(task, taskId) in filteredTasksByType(type)"
          :key="taskId"
          class="task-card"
          :class="getTaskCardColor(task.type)"
          :draggable="true"
          @dragstart="handleDragStart($event, taskId)"
          @dragend="handleDragEnd"
        >
          <div class="task-header">
            <h3>{{ task.title }}</h3>
            <span @click="confirmDelete(taskId)" class="delete-button">&#10006;</span>
          </div>
          <div v-if="task.content !== '' || editingTask === taskId">
            <textarea
              v-model="task.content"
              @input="updateTaskContent(taskId, task.content)"
              class="task-content-edit"
              placeholder="Add content here..."
            />
          </div>
          <!-- Replaced the dropdown with two buttons for task type change -->
          <div class="task-footer">
            <button
              v-for="typeOption in getOtherTypes(task.type)"
              :key="typeOption"
              @click="changeTaskType(taskId, typeOption)"
              class="task-type-button"
            >
              {{ typeOption }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h2>Pe bune?</h2>
        <p>Chiar stergi necesitatea asta?</p>
        <button @click="deleteTask">DA</button>
        <button @click="showModal = false">Of</button>
      </div>
    </div>
  </div>
</template>

<script>
import { database } from './firebase';
import { ref as dbRef, onValue, update, remove } from 'firebase/database';
import TaskCreation from './components/TaskCreation.vue';

export default {
  data() {
    return {
      tasks: {}, // Task list
      taskTypes: ['De luat', 'Luat', 'Ne trebuie?'],
      draggedTaskId: null,
      showModal: false,
      taskToDelete: null,
    };
  },
  components: {
    TaskCreation,
  },
  methods: {
    filteredTasksByType(type) {
      return Object.entries(this.tasks)
        // eslint-disable-next-line no-unused-vars
        .filter(([taskId, task]) => task.type === type) // Filter tasks by type
        .reduce((acc, [taskId, task]) => {
          acc[taskId] = task;
          return acc;
        }, {}); // Return the filtered tasks
    },
    handleDragStart(event, taskId) {
      this.draggedTaskId = taskId;
      event.dataTransfer.effectAllowed = 'move';
    },
    handleDragEnd() {
      this.draggedTaskId = null;
    },
    handleDrop(event, newType) {
      if (this.draggedTaskId) {
        const taskRef = dbRef(database, `tasks/${this.draggedTaskId}`);
        update(taskRef, { type: newType })
          .then(() => {
            console.log(`Task ${this.draggedTaskId} updated to type: ${newType}`);
          })
          .catch((error) => {
            console.error('Error updating task: ', error);
          });
      }
    },
    confirmDelete(taskId) {
      this.taskToDelete = taskId;
      this.showModal = true;
    },
    deleteTask() {
      if (this.taskToDelete) {
        const taskRef = dbRef(database, `tasks/${this.taskToDelete}`);
        remove(taskRef)
          .then(() => {
            console.log(`Task ${this.taskToDelete} deleted successfully.`);
            this.taskToDelete = null;
            this.showModal = false;
          })
          .catch((error) => {
            console.error('Error deleting task: ', error);
          });
      }
    },
    updateTaskContent(taskId, newContent) {
      const taskRef = dbRef(database, `tasks/${taskId}`);
      update(taskRef, { content: newContent });
    },
    changeTaskType(taskId, newType) {
      const taskRef = dbRef(database, `tasks/${taskId}`);
      update(taskRef, { type: newType })
        .then(() => {
          console.log(`Task ${taskId} moved to ${newType}`);
        })
        .catch((error) => {
          console.error('Error updating task type: ', error);
        });
    },
    getTaskCardColor(type) {
      switch (type) {
        case 'De luat':
          return 'red-card';
        case 'Luat':
          return 'green-card';
        case 'Ne trebuie?':
          return 'yellow-card';
        default:
          return '';
      }
    },
    getOtherTypes(currentType) {
      return this.taskTypes.filter((type) => type !== currentType);
    },
  },
  mounted() {
    const tasksRef = dbRef(database, 'tasks');
    onValue(tasksRef, (snapshot) => {
      this.tasks = snapshot.val() || {};
    });
  },
};
</script>

<style>
/* Same styles as before */
#app {
  max-width: 1200px;
  margin: 20px auto;
  text-align: center;
}
input[type="text"],
.task-content-edit {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}
button {
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
}
.task-columns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.column {
  flex: 1;
  min-width: 250px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.task-card {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: opacity 0.3s ease;
  cursor: grab;
  display: flex;
  flex-direction: column;
  word-wrap: break-word; /* Ensure text breaks properly */
  white-space: normal; /* Allow text to wrap */
  overflow-wrap: break-word; /* Force wrap for long words */
}

textarea.task-content-edit {
  width: 100%;
  resize: none;
  word-wrap: break-word; /* Wrap long text */
  white-space: normal; /* Ensure text wraps */
  overflow-wrap: break-word;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}
.red-card {
  background-color: #f28b82; /* Red */
}
.green-card {
  background-color: #a8e6cf; /* Green */
}
.yellow-card {
  background-color: #fff176; /* Yellow */
}
.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.task-header h3 {
  margin: 0;
}
.task-footer {
  margin-top: 10px;
}
.task-type-dropdown {
  padding: 5px;
  font-size: 1rem;
}
.task-content-edit {
  margin-top: 10px;
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  word-wrap: break-word; /* Ensures long words break to fit */
  white-space: normal; /* Ensures long content wraps instead of scrolling */
  overflow-wrap: break-word; /* Helps break long words in modern browsers */
}
.delete-button {
  cursor: pointer;
  font-size: 1.5rem;
  color: red;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  word-wrap: break-word; 
  white-space: normal;
}
@media (max-width: 768px) {
  .task-columns {
    flex-direction: column;
    align-items: center;
  }
  .column {
    width: 100%;
  }
}
textarea.task-content-edit {
  width: 100%;
  resize: none;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.task-footer {
  margin-top: 10px;
}

.task-type-button {
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.task-type-button:hover {
  background-color: #c5c5c5;
}

.red-card {
  background-color: #f28b82; /* Red */
}
.green-card {
  background-color: #a8e6cf; /* Green */
}
.yellow-card {
  background-color: #fff176; /* Yellow */
}

</style>
